<template>
  <div class="team-intro">
    <div class="bg-overlay"></div>
    <div class="titles">
        <h2 class="title">Social Media</h2>
        <p class="subtitle">
          <strong>Nuestro motor son las Redes Sociales.</strong> <br>
        Más de 1.000 Millones de personas hacen crecer su marca gracias al Marketing Digital.
        </p>
    </div>
  </div>
  <section id="targets" class="socials-info-container">
      <div class="info-header">
          <h3 class="title">
            Las Redes Sociales llegaron para cambiar la comunicación <br> <span>Y darle una nueva herramienta al Mercadeo.</span>
          </h3>

      </div>
      <div class="info-items-container">
          <div class="info-item">
              <span class="material-icons" style="color: #2C95DC;">system_update</span>
              <h4>Mantén siempre fresco tu contenido</h4>
              <p class="description">
                   Actualiza tu contenido y ofrece algo nuevo para cada post.
                   La monotonía aburre y nadie quiere más de lo mismo.
              </p>
          </div>
          <div class="info-item">
            <span class="material-icons" style="color: #27CB66;">supervised_user_circle</span>
              <h4>Interactúa con tu audiencia</h4>
              <p class="description">
                   Nadie más que tú necesita saber cómo está tu público, qué quiere y qué le gusta.
                   Habla con ellos y no dejes que te lo cuenten.
              </p>
          </div>
          <div class="info-item">
              <span class="material-icons" style="color: #E43C3C;">favorite</span>
              <h4>Brinda una experiencia</h4>
              <p class="description">
                   Más que un producto, compramos una experiencia, y con solo atender de
                   forma personalizada a tu audiencia, estás brindando una experiencia.
              </p>
          </div>
      </div>
  </section>
  <section id="portfolio" class="socials-portfolio-container">
      <div class="info-header">
          <h3 class="title">Una muestra de nuestro esfuerzo</h3>
          <span class="material-icons">design_services</span>
      </div>
      <div class="socials-portfolio">
        <div class="socialmedia-image-mini post-1-image" @click="showModal('post-1')"></div>
        <div class="socialmedia-image-mini post-2-image" @click="showModal('post-2')"></div>
        <div class="socialmedia-image-mini post-3-image" @click="showModal('post-3')"></div>
        <div class="socialmedia-image-mini post-4-image" @click="showModal('post-4')"></div>
      </div>
  </section>
  <section id="goals" class="socials-info-container">
      <div class="info-header">
          <h3 class="title" style="width: 100%;">
                Somos amantes del arte, la innovación y la música,
                de eso nos inspiramos para hacer del marketing digital, las redes sociales
                 y el diseño web, una mejor experiencia.
          </h3>

      </div>
      <div class="info-items-container">
          <div class="info-item">
            <span class="material-icons" style="color: #7B3DD8;">assessment</span>
              <h4>Análisis</h4>
              <p class="description">
                Analizamos la situación de tu marca en el mercado: percepción, competencia, imagen, oportunidades.
              </p>
          </div>
          <div class="info-item">
            <span class="material-icons" style="color: #E3B63B;">tips_and_updates</span>
              <h4>Creatividad</h4>
              <p class="description">
                   Desarrollamos el plan de acción adaptado a las necesidades y posibilidades de cada cliente,
                    a través de soluciones creativas.
              </p>
          </div>
          <div class="info-item">
            <span class="material-icons" style="color: #0F06AC;">trending_up</span>
              <h4>Viralidad</h4>
              <p class="description">
                   Buscamos la máxima difusión del mensaje por medio de acciones de comunicación,
                    publicidad, relaciones públicas, eventos.
              </p>
          </div>
      </div>
  </section>


    <!-- Modals -->
    <div id="post-1" class="modal" @click="closeModal('post-1')">
        <span class="close" id="modal-close" @click="closeModal('post-1')">&times;</span>
        <div class="modal-content post-1-image"></div>
    </div>

    <div id="post-2" class="modal" @click="closeModal('post-2')">
        <span class="close" id="modal-close" @click="closeModal('post-2')">&times;</span>
        <div class="modal-content post-2-image"></div>
    </div>

    <div id="post-3" class="modal" @click="closeModal('post-3')">
        <span class="close" id="modal-close" @click="closeModal('image-15')">&times;</span>
        <div class="modal-content post-3-image"></div>
    </div>

    <div id="post-4" class="modal" @click="closeModal('post-4')">
        <span class="close" id="modal-close" @click="closeModal('post-4')">&times;</span>
        <div class="modal-content post-4-image"></div>
    </div>

  <QuoteBlock/>
</template>

<script>
import QuoteBlock from '@/components/QuoteBlock.vue'

export default {
  name: 'SocialMedia',
  components: {
    QuoteBlock
  },
  methods: {
    showModal (id) {
      var modal = document.getElementById(id);
      modal.style.display = 'block';
    },
    closeModal (id) {
      var modal = document.getElementById(id);
      modal.style.display = 'none';
    }
  }
}
</script>

<style scoped>
section{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 3%;
}

.team-intro{
    background-image: url('../../assets/img/team-social_media-banner_intro.jpg');
}

.socials-info-container .info-header{
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-items-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    height: 60%;
}
.info-item{
    min-width: 200px;
    width: 30%;
}

.socials-portfolio-container .info-header{
    height: 30%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
}

.socials-portfolio img {
    height: 250px;
    width: 360px;
}

.socials-portfolio {
    display: grid;
    justify-content: center;
    grid-gap: 10%;
    grid-template-columns: repeat(auto-fill, 250px);
    height: 70%;
}

.info-header .title{
    font-size: 2rem;
    width: 60%;
    margin-bottom: 5%;
}
.info-header .title span{
    font-size: 1rem;
    font-weight: lighter;
}

.material-icons {
    font-size: 14vh;
}

#goals .title{
    font-size: 1.5rem;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.close {
    cursor: pointer;
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    top: 10px;
    right: 20px;
}

.modal-content {
    border-radius: 5px;
    background-color: #fefefe;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    height: 100%;
    border: 1px solid #888;
    width: 75%;
}

.socialmedia-image-mini {
    height: 250px;
    width: 250px;
    margin: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer; 
}

.post-1-image {
    background-image: url(../../assets/media/1.jpg);
}

.post-2-image {
    background-image: url(../../assets/media/3-800x528.jpg);
}

.post-3-image {
    background-image: url(../../assets/media/6.jpg);
}

.post-4-image {
    background-image: url(../../assets/media/7-800x531.jpg);
}

@media screen and (max-width: 600px) {

    .socials-portfolio {
        grid-gap: 0;
    }
    .info-header .title {
        width: 100%;
    }
    .info-item {
        width: 90%;
    }
    .title img {
        width: 150px;
        height: 150px;
    }
    .socials-portfolio {
        height: 50%;
    }
    .socials-portfolio img {
        width: 40%;
    }
    #portfolio .info-header .title {
        font-size: x-large;
        width: 65%;
    }
    #goals .info-header .title {
        font-size: large;
    }
    .quote-container {
        height: 40%;
    }
    .socials-portfolio img {
        height: 20vh;
        width: 20vh;
    }
    .material-icons {
        font-size: 12vh;
    }
    /* Mobile large */
    @media screen and (min-width: 380px) and (orientation: portrait) {
        #targets {
            margin-top: 10%;
        }
        #portfolio {
            margin-top: 45%;
        }
        .socials-info-container .info-header {
            height: 20%;
        }
        .quote-container {
            margin-top: 35%;
            height: 40%;
        }
    }
}
</style>
